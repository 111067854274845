<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body>
        <b-row class="mb-0">
          <b-col cols="2" class="borderRight pr-0">
            <b-card-body>
              <b-card-title>推理过程</b-card-title>

              <flat-pickr v-model="filterValues.time" placeholder="时间" :config="{
                                time_24hr: true,
                                mode: 'range',
                                locale: $i18n.locale === 'cn' ? 'zh' : 'en',
                            }" class="form-control"/>
              <v-select v-model="filterValues.system" :options="filterOptions.System" placeholder="系统"
                        class="w-100 mt-1"/>
              <v-select :inputmode="false" v-model="filterValues.eqname" :options="filterOptions.Eqname"
                        placeholder="设备" class="w-100 mt-1"/>
              <v-select v-model="filterValues.degree" :options="filterOptions.Degree" placeholder="等级"
                        class="w-100 mt-1"/>
              <v-select v-model="filterValues.secondary_class" :options="filterOptions.Secondary"
                        placeholder="类型" class="w-100 mt-1"/>
            </b-card-body>

            <hr class="my-0"/>

            <vue-perfect-scrollbar style="height: calc(100vh - 524px)">
              <div v-if="!myDataArray.length && !loading" style="
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                " class="text-center">
                <div>未查询到数据</div>
                <b-icon-exclamation-triangle-fill scale="5" class="my-3"/>
                <div class="text-muted">No items found</div>
              </div>

              <div v-else-if="loading" style="
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                " class="text-center">
                <div>努力加载中...</div>
                <b-spinner label="Loading..." style="width: 4rem; height: 4rem" class="my-2"
                           variant="primary"/>

                <div class="text-muted">Loading ...</div>
              </div>

              <b-list-group flush class="listGroupSelect">
                <b-list-group-item v-for="(item,index) of myDataArray" @click="hanleClickTreeItem(item)"
                                   :key="index"
                                   :style="selectFaultId === item.id ? `border-left: 3px solid #1890ff;` : ''">
                                    <span class="bullet bullet-sm mr-1" :class="'bullet-' +
                                        getDegreeColor(
                                            item.degree
                                        )
                                        "></span>{{ item.fault_name }}
                </b-list-group-item>
              </b-list-group>
            </vue-perfect-scrollbar>

            <b-card-footer class="pt-1 d-flex align-items-center justify-content-between text-muted">
              <span>共扫描到</span>
              <b-badge pill variant="light-primary">
                {{
                  myDataArray.length
                }}
              </b-badge>
              <span>风险漏洞</span>
            </b-card-footer>
          </b-col>
          <b-col cols="10" class="pl-0">
            <AgentDetailRight v-if="detail.isSelected" ref="agentDetailRight"></AgentDetailRight>
            <div v-else style="
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            " class="text-center">
              <div>
                <b-icon-arrow-left-circle-fill class="mr-25"></b-icon-arrow-left-circle-fill>
                选择需要查看的风险事件
              </div>
              <b-icon-envelope-fill scale="5" class="my-3"></b-icon-envelope-fill>
              <div class="text-muted">Nothing is selected</div>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import PageTitle from "@/views/layout/PageTitle.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import JsonExcel from "vue-json-excel";
import VJstree from "vue-jstree";
import {Graph} from "@antv/x6";
import {onMounted, ref, reactive, toRefs, watch, nextTick} from "@vue/composition-api";
import {Line, Area} from "@antv/g2plot";
import FlowChart from "./FlowChart.vue";
import DagDemo from "./components/DagDemo.vue";
import {t} from "@core/libs/i18n/utils";
import flatPickr from "vue-flatpickr-component";
import {momentJs} from "@/libs/utils/moment";
import zh from "flatpickr/dist/l10n/zh.js";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import {scroll_active, scroll_stop} from "@/libs/utils/autoScroll";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {
  detail_get_degree,
  detail_get_results,
  detail_get_type,
  fault_detail,
  getDiagnosisDetailSearchOptions, getDiagnosisFaultChartJson,
} from "@/api/diagnosis";
import AgentDetailRight from "@/views/front/common/agent-list/components/AgentDetailRight.vue";

// import auto_import_panel_components from "@/views/panel-group/components";

export default {
  name: "AgentDetail",
  directives: {
    Ripple,
  },
  components: {
    AgentDetailRight,
    PageTitle,
    vSelect,
    flatPickr,
    downloadExcel: JsonExcel,
    VJstree,
    FlowChart,
    DagDemo,
    AppTimeline,
    AppTimelineItem,
    VuePerfectScrollbar,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      selectFaultId: null,
    };
  },
  setup() {
    // 使用 ref 创建响应式数据
    const myDataArray = ref([]);
    const event = reactive({
      loading: false,
      filterValues: {
        time: `${momentJs().format("YYYY-MM-DD")} 至 ${momentJs().format("YYYY-MM-DD")}`,
        degree: '',
        secondary_class: '',
        eqname: '',
        system: '',
      },
      filterOptions: {
        Type: [],
        Degree: [],
        Secondary: [],
        System: [],
        Eqname: [],
      },
      projectOperation: [],
      detail: {
        isSelected: false,
        create_date: "",
        system: "",
        degree: "",
        function_name: "",
        fault_name: "",
        fault_reason: "",
      },
      searchShow: false,
      infoShow: false,
    });

    // 使用 ref 创建 graph
    // const graph = ref(null);

    const formatTime = () => {
      if (event.filterValues.time) {
        const times = event.filterValues.time.split("至");
        if (times.length <= 1) return times[0];
        return `${times[0].trim()},${times[1].trim()}`;
      }
      return null;
    };
    watch(
        [
          () => event.filterValues.time,
          () => event.filterValues.degree,
          () => event.filterValues.secondary_class,
          () => event.filterValues.eqname,
          () => event.filterValues.system,
        ],
        (v) => {
          // detail_get_results({
          //   degree: v.value,
          //   time: event.filterValues.time,
          //   type: event.filterValues.Type.value,
          // }).then((res) => {
          //   for (let i in res.data) {
          //     const t = {
          //       text: i,
          //       opened: false,
          //       children: res.data[i].map((item) => {
          //         return {
          //           text: item.function_name,
          //           id: item.id,
          //           data: item,
          //         };
          //       }),
          //     };
          //     myDataArray.value.push(t);
          //   }
          const time = formatTime();
          const params = {};
          myDataArray.value = []
          Object.entries(event.filterValues).forEach((item) => {
            params[item[0]] = item[1]?.value ?? null;
          });
          event.loading = true
          detail_get_results({...params, time}).then((res) => {
            myDataArray.value = res.data
          }).finally(() => {
            event.loading = false
          });
        }
    );
    // 定义方法
    //     let areaPlot;

    const getDegreeColor = (degree) => {
      switch (degree) {
        case "一般":
          return "info";
        case "提醒":
          return "warning";
        case "严重":
          return "danger";
      }
    };


    // 返回需要在模板中使用的数据和方法
    return {
      ...toRefs(event),
      myDataArray,
      zh,
      scroll_active,
      scroll_stop,
      getDegreeColor
    };
  },
  mounted() {
    getDiagnosisDetailSearchOptions().then((res) => {
      const data = res.data;
      // degree_fields
      // secondary_class_fields
      // system_fields
      // type_fields
      this.filterOptions.Degree = data.degree_fields
          .filter((item) => item.degree)
          .map((item) => ({
            value: item.degree,
            label: item.degree,
          }));
      this.filterOptions.Secondary = data.secondary_class_fields
          .filter((item) => item.secondary_class)
          .map((item) => ({
            value: item.secondary_class,
            label: item.secondary_class,
          }));
      this.filterOptions.System = data.system_fields
          .filter((item) => item.system)
          .map((item) => ({
            value: item.system,
            label: item.system,
          }));
      this.filterOptions.Type = data.type_fields
          .filter((item) => item.type)
          .map((item) => ({
            value: item.type,
            label: item.type,
          }));
      this.filterOptions.Eqname = data.eqname_fields
          .filter((item) => {
            return item && item.eqname
          })
          .map((item) => {
            return ({
              value: item.eqname,
              label: item.eqname,
            })
          });
      this.filterValues.time = `${momentJs().format("YYYY-MM-DD")} 至 ${momentJs().format("YYYY-MM-DD")}`
    });
    // scroll_active("#project-detail-operation-scroll-notice");
    // initareaPlot();
    if (!this.$route.params.detail && this.$route.params.id) {
      this.loading = true
      fault_detail(this.$route.params.id).then((res) => {
        this.detail = res.data;
        const data = JSON.parse(this.detail.data);
        const chartData = [];
        for (let i in data.time) {
          const d = new Date(data.time[i]);
          chartData.push({
            time: `${d.getFullYear()}-${d.getMonth() + 1 < 10
                ? "0" + (d.getMonth() + 1)
                : d.getMonth() + 1
            }-${d.getDate() + 1 < 10
                ? "0" + (d.getDate() + 1)
                : d.getDate() + 1
            } ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`,
            pvvarname: data.pvvarname[i],
            value: data.value[i],
          });
        }
        this.detail.chartData = chartData;
        this.initareaPlot(this?.detail?.chartData);
      }).finally(() => {
        this.loading = false
      });
    } else if (this.$route.params.detail) {
      this.detail = this.$route.params.detail;
      this.initareaPlot(this?.detail?.chartData);
    } else {
      this.detail = {
        isSelected: false,
        create_date: "",
        system: "",
        degree: "",
        function_name: "",
        fault_name: "",
        fault_reason: "",
        eqname: "",
      }
    }
  },
  methods: {
    initareaPlot(chartData) {
      this.$refs.agentDetailRight.initareaPlot(chartData);
      // if (!chartData) return;
      // if (areaPlot) {
      //   areaPlot.data = chartData;
      //   areaPlot.update();
      //   return;
      // }
      // areaPlot = new Area("canvas", {
      //   data: chartData,
      //   xField: "time",
      //   yField: "value",
      //   xAxis: {
      //     grid: null,
      //   },
      //   yAxis: {
      //     grid: null,
      //   },
      //   legend: null,
      //   animate: true,
      //   animation: {
      //     appear: {
      //       animation: "wave-in",
      //       duration: 10000,
      //     },
      //   },
      // });
      //
      // areaPlot.render();
    },
    hanleClickTreeItem(v) {
      this.detail.isSelected = false
      nextTick(() => {
        const detail = v;
        this.selectFaultId = v.id
        getDiagnosisFaultChartJson({id: v.id}).then(res => {
          console.log(res, 'res')
          const data = JSON.parse(res.data.data);
          const chartData = [];
          for (let i in data.time) {
            const d = new Date(data.time[i]);
            chartData.push({
              time: `${d.getFullYear()}-${d.getMonth() + 1 < 10
                  ? "0" + (d.getMonth() + 1)
                  : d.getMonth() + 1
              }-${d.getDate() + 1 < 10
                  ? "0" + (d.getDate() + 1)
                  : d.getDate() + 1
              } ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`,
              pvvarname: data.pvvarname[i],
              value: data.value[i],
            });
          }
          detail.chartData = chartData;
          for (let i in this.detail) {
            this.detail[i] = detail[i];
          }
          this.detail.isSelected = true
          nextTick(() => {
            this.$refs.agentDetailRight.update(detail);
            this.initareaPlot(chartData);
          })
        })

      })


    },
    toAgentDetail() {
      this.$router.replace({
        name: "AgentDetail",
        params: this.$route.params,
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../../@core/scss/vue/libs/vue-flatpicker";
@import "../../../../@core/scss/vue/libs/vue-select";

/* 在你的样式文件中添加以下样式 */
// .jstree-clicked {
//   background-color: red !important;
// }/* 添加自定义样式，根据实际需求调整 */
// .tree-default .tree-wholerow-clicked {
//   background-color: red !important;
// }

.tree-default ::v-deep .tree-selected {
  // background: #b40a0a !important;
  background: unset !important;
  border: 0;
  box-shadow: none;
}

.tree-default ::v-deep .tree-node {
  min-height: 24px;
  line-height: 24px;
  margin-left: 30px;
  min-width: 300px !important;
}

.row {
  margin-bottom: 5px;
}

.text1 {
  // font-weight: bold;
  // font-size: 14px;
  // color: black;
}

.text2 {
  margin-bottom: 3px;
  font-size: 14px;
}

.text4 {
  font-size: 10px;
}

.card_text {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.red-text-shadow {
  text-shadow: 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red;
}

.info-text-shadow {
  text-shadow: 0 0 0 rgb(98, 204, 230), 0 0 0 rgb(98, 204, 230),
  0 0 0 rgb(98, 204, 230), 0 0 0 rgb(98, 204, 230);
}

.success-text-shadow {
  text-shadow: 0 0 0 rgb(100, 195, 119), 0 0 0 rgb(100, 195, 119),
  0 0 0 rgb(100, 195, 119), 0 0 0 rgb(100, 195, 119);
}

// .v-select {
//   background-color: transparent !important;
//   border: 1px solid #ced4da; /* 设置你想要的边框颜色 */
// }

.timeline-item {
  padding-left: 1.5rem !important;

  div > h6 {
    font-size: 0.9rem !important;
  }
}

.left-search-where {
  max-height: 20px;
  overflow-y: scroll;
}

.right-info {
  position: absolute;
  right: 0;
  top: 0;
  width: 300px;
  height: 20px;
  overflow: hidden;
}

.right-info-show {
  position: absolute;
  right: 0;
  top: 0;
  width: 300px;
}

.left-search-where-show {
  max-height: calc(100vh - 120px);
}

/* Lucas */
.listGroupSelect {
  .list-group-item {
    border: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    cursor: pointer;

    &:hover {
      transform: translateY(-2px);
      transition: all 0.2s;
    }
  }
}
</style>
